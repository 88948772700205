/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/main-section/main-section.component.ngfactory";
import * as i3 from "../../components/main-section/main-section.component";
import * as i4 from "../../components/main-features/main-features.component.ngfactory";
import * as i5 from "../../components/main-features/main-features.component";
import * as i6 from "../../components/extra-features/extra-features.component.ngfactory";
import * as i7 from "../../components/extra-features/extra-features.component";
import * as i8 from "../../components/download/download.component.ngfactory";
import * as i9 from "../../components/download/download.component";
import * as i10 from "../../components/contact/contact.component.ngfactory";
import * as i11 from "../../components/contact/contact.component";
import * as i12 from "./home.component";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "main-section", [], null, null, null, i2.View_MainSectionComponent_0, i2.RenderType_MainSectionComponent)), i1.ɵdid(1, 49152, null, 0, i3.MainSectionComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "main-features", [], null, null, null, i4.View_MainFeaturesComponent_0, i4.RenderType_MainFeaturesComponent)), i1.ɵdid(3, 49152, null, 0, i5.MainFeaturesComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "extra-features", [], null, null, null, i6.View_ExtraFeaturesComponent_0, i6.RenderType_ExtraFeaturesComponent)), i1.ɵdid(5, 49152, null, 0, i7.ExtraFeaturesComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "component-download", [], null, null, null, i8.View_DownloadComponent_0, i8.RenderType_DownloadComponent)), i1.ɵdid(7, 49152, null, 0, i9.DownloadComponent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "component-contact", [], null, null, null, i10.View_ContactComponent_0, i10.RenderType_ContactComponent)), i1.ɵdid(9, 49152, null, 0, i11.ContactComponent, [], null, null)], null, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i12.HomeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i12.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
