<section id="download">
    <div class="container">
        <div class="row d-md-flex text-center wow fadeIn">
            <div class="col-md-6 offset-md-3 col-sm-10 offset-sm-1 col-xs-12">
                <h2>Download & get started today!</h2>
                <p class="mt-5">
                    <a href="https://itunes.apple.com/us/app/cooking-calculator/id1373714056?ls=1&mt=8" target="_blank">
                        <img src="assets/img/apple_store.png" class="store-img"/> 
                    </a>
                </p>
                <p class="mt-4 lead">
                    We also appreciate any feedback, features that you would like to in the Cooking Calculator please get in touch with us.<br/>
                </p>
            </div>
        </div>
    </div>
</section>