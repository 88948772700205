/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./main-section.component";
var styles_MainSectionComponent = [i0.styles];
var RenderType_MainSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainSectionComponent, data: {} });
export { RenderType_MainSectionComponent as RenderType_MainSectionComponent };
export function View_MainSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "section", [["class", "bg-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "col-md-6 text-white wow fadeIn topsection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [["class", "text-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cooking Calculator"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h3", [["class", "text-primary"], ["style", "margin-top: 24px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "text-orange"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your cooking little helper!"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "mt-4 lead text-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" It will help you easily convert the most common measuring units used in cooking and baking! "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "p", [["class", "mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["href", "https://itunes.apple.com/us/app/cooking-calculator/id1373714056?ls=1&mt=8"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["class", "store-img"], ["src", "assets/img/apple_store.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "col-md-6 d-none d-md-block wow fadeInRight"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "img", [["class", "img-fluid mx-auto d-block appstore-img"], ["src", "assets/img/1.png"], ["width", "300"]], null, null, null, null, null))], null, null); }
export function View_MainSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "main-section", [], null, null, null, View_MainSectionComponent_0, RenderType_MainSectionComponent)), i1.ɵdid(1, 49152, null, 0, i2.MainSectionComponent, [], null, null)], null, null); }
var MainSectionComponentNgFactory = i1.ɵccf("main-section", i2.MainSectionComponent, View_MainSectionComponent_Host_0, {}, {}, []);
export { MainSectionComponentNgFactory as MainSectionComponentNgFactory };
