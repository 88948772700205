import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'component-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
	currentDate: number = new Date().getFullYear();
}
