<section class="bg-dark text-white extra-features" id="extra-features">
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-xs-12 text-center wow fadeIn">
                <h2 class="text-orange">Features & Details</h2>
                <p class="lead">
                    Built for everyone.<br/> Made for all your cooking recipes.
                </p>
            </div>
        </div>
        <div class="row d-md-flex mt-5">
            <div class="col-sm-4 wow fadeIn">
                <h5 class="text-orange">Quickly scale your recipes!</h5>
                <p>
                    Our free Cooking Calculator allows you to scale your recipes with no math involved.
                </p>
                <h5 class="text-orange pt-5">Ingredients</h5>
                <p>
                    Convert by ingredient between common volume and weight units for more than 250+ ingredients!
                </p>

                <h5 class="text-orange pt-5">Unique Design</h5>
                <p>
                    We did a lot of research on the usability of the app. Conducted a lot of tests with customers to offer you the best UX/UI.
                </p>
            </div>
            <div class="col-sm-4 wow fadeIn">
                <img class="img-fluid mx-auto d-block" src="assets/img/screen.png" alt="Gallery">
            </div>
            <div class="col-sm-4 wow fadeIn">                            
                
                <h5 class="text-orange pt-5">Volume Converter</h5>
                <p>
                    Convert between units of teaspoons, tablespoons, cups, pints, quarts, gallons, fluid ounces, millilitres, litres, drops and many more...
                </p>

                <h5 class="text-orange">Weight Converter</h5>
                <p>
                    The dry/weight conversion depends on the ingredient. Convert between different units of weight and volume for cooking ingredients.
                </p>

                <h5 class="text-orange pt-5">Convert European to U.S. measurements</h5>
                <p>
                    Supports weight to volume conversions, allowing you to convert ingredients from cups to grams and vice versa. 
                </p>
            </div>
        </div>
    </div>
</section>