import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { SupportComponent } from './pages/support/support.component';
import { MainSectionComponent } from './components/main-section/main-section.component';
import { MainFeaturesComponent } from './components/main-features/main-features.component';
import { DownloadComponent } from './components/download/download.component';
import { ContactComponent } from './components/contact/contact.component';
import { ExtraFeaturesComponent } from './components/extra-features/extra-features.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

import { appRoutes } from './pages/router';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PrivacyComponent,
    SupportComponent,
    MainSectionComponent,
    MainFeaturesComponent,
    DownloadComponent,
    ContactComponent,
    ExtraFeaturesComponent,
    NotFoundComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes
      // { enableTracing: true }
    ),
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { 
  public ngOnInit() {
      console.log("read")
    }
}
