<section class="bg-texture-collage p-0" id="support">
    <div class="container">
        <div class="row d-md-flex text-white text-center wow fadeIn">
            <div class="col-md-4 p-5">
                <p><em class="material-icons dp36">email</em></p>
                <p>features-cc-app@itcarst.com</p>
            </div>
            <div class="col-md-4 p-5">
                <p><em class="material-icons dp36">email</em></p>
                <p>cooking-app-support@itcarst.com</p>
            </div>
            <div class="col-md-4 p-5">
                <p><em class="material-icons dp36">location_on</em></p>
                <p>Amsterdam, Netherlands</p>
            </div>
        </div>
    </div>
</section>