<!--footer-->
<section class="bg-dark pt-5" id="connect">
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-xs-12 text-center wow fadeIn">
                <h2 class="hero-heading text-muted">Cooking Calculator</h2>                
                <p class="pt-2 text-muted">
                    iTCarst: &copy; {{currentDate}} Cooking Calculator
                </p>
            </div>
        </div>
    </div>
</section>