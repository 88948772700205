/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./contact.component";
var styles_ContactComponent = [i0.styles];
var RenderType_ContactComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactComponent, data: {} });
export { RenderType_ContactComponent as RenderType_ContactComponent };
export function View_ContactComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "section", [["class", "bg-texture-collage p-0"], ["id", "support"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 18, "div", [["class", "row d-md-flex text-white text-center wow fadeIn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col-md-4 p-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "em", [["class", "material-icons dp36"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["email"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["features-cc-app@itcarst.com"])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "col-md-4 p-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "em", [["class", "material-icons dp36"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["email"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["cooking-app-support@itcarst.com"])), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["class", "col-md-4 p-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "em", [["class", "material-icons dp36"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["location_on"])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Amsterdam, Netherlands"]))], null, null); }
export function View_ContactComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "component-contact", [], null, null, null, View_ContactComponent_0, RenderType_ContactComponent)), i1.ɵdid(1, 49152, null, 0, i2.ContactComponent, [], null, null)], null, null); }
var ContactComponentNgFactory = i1.ɵccf("component-contact", i2.ContactComponent, View_ContactComponent_Host_0, {}, {}, []);
export { ContactComponentNgFactory as ContactComponentNgFactory };
